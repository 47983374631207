<template><div>reloading</div></template>

<script>
import { logoutMixin } from '@/modules/dashboard/mixins/logoutMixin';

export default {
  name: 'Reload',
  mixins: [logoutMixin],
  mounted() {
    this.logoutAndClearModule();
  }
};
</script>
